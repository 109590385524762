import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CalendarIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";


const SpinnerSmall = () => {
  return (
    <div className="h-8 w-8 animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"></div>
  );
};

export const BookingSelector = ({
  title,
  open,
  setOpen,
  bookingSlots,
  action,
  duration,
  type,
  isFetching,
  interviewType
}) => {
  const { t } = useTranslation();
  const cancelButtonRef = useRef(null);
  const [selectedSlot, setSelectedSlot] = useState(null);

  const handleSave = () => {
    if (selectedSlot === null) {
      return;
    }
    action(bookingSlots[selectedSlot]);
  };

  const getLabel = (slot) => {
    const start = new Date(slot.start).toLocaleString("fi-FI", {
      timeZone: 'Europe/Helsinki',
      weekday: "short",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });

    const end = new Date(slot.end).toLocaleTimeString("fi-FI", {
      timeZone: 'Europe/Helsinki',
      timeZoneName: "short",
      hour: "numeric",
      minute: "numeric",
    });

    return `${start} - ${end}`;
  };

  const activeClass =
    "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2";
  const disabledClass =
    "inline-flex w-full justify-center rounded-md bg-indigo-300 px-3 py-2 text-sm font-semibold text-gray-100 shadow-sm sm:col-start-2";

  const isDisabled = selectedSlot === null || isFetching;

  const typeTranslationKey = `Process.Properties.${type}`;

  const durationLabel = `BookingSelector.${interviewType}.Duration`;
  const typeLabel = `BookingSelector.${interviewType}.Type`;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="flex flex-wrap justify-center">
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                    <CalendarIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="px-12 flex-1">
                    <fieldset>
                      <legend className="text-lg font-semibold text-gray-900">
                        {title}
                      </legend>
                      <br />
                      {duration && (
                      <p className="text-gray-500">
                        {t(durationLabel)} {duration} min
                      </p>
                      )}
                      <p className="text-gray-500">{t(typeLabel)} {t(typeTranslationKey, type)}</p>

                      <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
                        {!isFetching && bookingSlots.map(
                          (slot, index) => (
                            <div
                              key={index + "_bookingSlot"}
                              className="relative flex items-start py-2"
                            >
                              <div className="min-w-0 flex-1 text-sm leading-6">
                                <label
                                  htmlFor={index + "_bookingSlot"}
                                  className="select-none font-medium text-gray-900"
                                >
                                  {getLabel(slot)}
                                </label>
                              </div>
                              <div className="ml-3 flex h-6 items-center">
                                <input
                                  id={index + "_bookingSlot"}
                                  name="bookingSlot"
                                  type="radio"
                                  value={index}
                                  onChange={(event) =>
                                    setSelectedSlot(
                                      parseInt(event.target.value)
                                    )
                                  }
                                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                              </div>
                            </div>
                          )
                        )}
                        {bookingSlots.length === 0 && !isFetching && (
                          <p className="my-4">{t("BookingSelector.NoTimesAvailable")}</p>
                        )}
                      </div>
                    </fieldset>
                    {isFetching && (
                      <div className="container flex align-middle justify-center my-4">
                        <SpinnerSmall />
                      </div>
                    )}
                  </div>

                  <div className="flex h-6 w-6 items-center justify-center rounded-full">
                    <XMarkIcon
                      className="h-6 w-6 text-gray-600"
                      aria-hidden="true"
                      onClick={() => setOpen(false)}
                    />
                  </div>
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className={
                      isDisabled ? disabledClass : activeClass
                    }
                    disabled={isDisabled}
                    onClick={() => handleSave()}
                  >
                    {t("BookingSelector.ScheduleMeeting")}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    {t("BookingSelector.Cancel")}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
