import { AuthenticatedTemplate } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useParams,
  useNavigate,
  createSearchParams,
  Link,
} from "react-router-dom";
import { PresentationChartBarIcon } from "@heroicons/react/20/solid";
import {
  getProcessChangeHistoryById,
  getProcessById,
  saveProcess
} from "../api";
import { Notification } from "../components/Notification";
import { BasicDetailsForm } from "./BasicDetailsForm";
import { InterviewsForm } from "./InterviewsForm";
import { TestsAndMethodsForm } from "./TestsAndMethodsForm";
import { CriteriaForm } from "./CriteriaForm";
import { ApplicantsForm } from "./ApplicantsForm";
import { InvitationMailForm } from "./InvitationMailForm";
import { Overlay } from "../components/Overlay";
import { isEmptyJson, isNotBlank, isPositiveOrZero } from "../utils/uiUtils";
import {
  getInitialForm,
  addMissingProperties,
  employeeTypes
} from "./ProcessUtils";
import { LoadingIndicator } from "../components/LoadingIndicator";
import { GrayPill, GreenPill } from "../components/Pills";
import { WarnBanner } from "../components/Alert";
import ChangeHistory from "../components/ChangeHistory";
import BreadCrumbs from "../components/BreadCrumbs";
import Tabs from "../components/Tabs";
import TabsFooter from "../components/TabsFooter";
import ProcessActions from "./ProcessActions";
import * as Yup from "yup";

export function ProcessForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [readyToPublish, setReadyToPublish] = useState(false);
  const [processPublished, setProcessPublished] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [savedSuccessfully, setSavedSuccessfully] = useState(false);
  const [processLoading, setProcessLoading] = useState(false);
  const [changeHistoryLoading, setChangeHistoryLoading] = useState(false);
  const [silentSaveMode, setSilentSaveMode] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [changeHistory, setChangeHistory] = useState({});
  const [showOverlay, setShowOverlay] = useState(false);

  const { id } = useParams();
  const isNewProcess = !id;
  const draftState = "Draft";
  const publishedState = "Published";
  const archivedState = "Archived";
  const processType = isNewProcess ? "CreateProcess" : "UpdateProcess";
  const validAssessmentLevels = ["Manager", "Expert", "Foreperson", "Official"];
  const validInterviewTypes = ["Teams", "Office", "NoInterview"];

  const tabs = [
    { name: t("Process.Tabs.BasicDetails"), current: activeTab === 1, index: 1, section: "basic" },
    { name: t("Process.Tabs.Interviews"), current: activeTab === 2, index: 2, section: "interviews" },
    { name: t("Process.Tabs.TestsAndMethods"), current: activeTab === 3, index: 3, section: "assessments" },
    { name: t("Process.Tabs.Criteria"), current: activeTab === 4, index: 4, section: "criteria" },
    { name: t("Process.Tabs.Applicants"), current: activeTab === 5, index: 5, section: "applicants" },
    { name: t("Process.Tabs.InvitationMail"), current: activeTab === 6, index: 6,  section: "" }
  ];

  const initialForm = Object.freeze(
    getInitialForm(processType, id, draftState)
  );
  const [formData, updateFormData] = useState(
    JSON.parse(JSON.stringify(initialForm))
  );

  const titleTranslationKey = isNewProcess
  ? "Process.Title.Create"
  : formData.processState === publishedState || formData.processState === draftState
  ? "Process.Title.Update"
  : formData.processState === archivedState
  ? "Process.Title.Archived"
  : "";

  const processName =
    formData.jobTitle && formData.customer?.name
      ? `${formData.jobTitle} | ${formData.customer.name}`
      : "";

  const breadcrumbs = [
    { label: t("BreadCrumbs.Processes"), url: "/" },
    { label: processName, url: window.location.pathname },
  ];

  const EmployeeSchema = Yup.object().shape({
    ordinal: Yup.number().optional(),
    email: Yup.string()
      .email(t("Process.BasicDetails.Section3.Validation.EmailFormat"))
      .optional(),
  });

  const RequiredEmployeeSchema = Yup.object().shape({
    ordinal: Yup.number().optional(),
    email: Yup.string().when("$currentState", (currentState, schema) => {
      if (currentState.includes(publishedState)) {
        return schema
          .required(
            t("Process.BasicDetails.Section3.Validation.AtLeastOneEmail")
          )
          .email(t("Process.BasicDetails.Section3.Validation.EmailFormat"));
      }
      return schema
        .optional()
        .email(t("Process.BasicDetails.Section3.Validation.EmailFormat"));
    }),
  });

  const CriteriaSchema = Yup.object().shape({
    criteriaTemplateId: Yup.number().optional(),
    customContent: Yup.string()
      .optional()
      .max(500, t("Process.Criteria.Validation.Content", { maxChars: "500" })),
  });

  const CustomCriteriaSchema = Yup.object().shape({
    id: Yup.number().optional(),
    ordinal: Yup.number().required(),
    title: Yup.string()
      .required(t("Errors.Mandatory"))
      .max(100, t("Process.Validation.MaxLength", { maxChars: "100" })),
    content: Yup.string()
      .required(t("Errors.Mandatory"))
      .max(500, t("Process.Validation.MaxLength", { maxChars: "500" })),
  });

  const DateSchema = Yup.string()
    .nullable()
    .matches(/^(20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/, {
      message: t("Process.Applicants.Validation.InvalidDate"),
      excludeEmptyString: true,
    });

  const TimeSchema = Yup.string()
    .nullable()
    .matches(/^([01]\d|2[0-3]):[0-5]\d:[0-5]\d$/, {
      message: t("Process.Applicants.Validation.InvalidTime"),
      excludeEmptyString: true,
    });

  const ApplicantSchema = Yup.object().shape({
    ordinal: Yup.number().optional(),
    candidateId: Yup.string().optional(),
    email: Yup.string().when("$currentState", (currentState, schema) => {
      if (currentState.includes(publishedState)) {
        return schema
          .required(t("Process.Applicants.Validation.EmailRequired"))
          .email(t("Process.Applicants.Validation.EmailFormat"));
      }
      return schema
        .optional()
        .email(t("Process.Applicants.Validation.EmailFormat"));
    }),
    phoneNumber: Yup.string()
      .nullable()
      .notRequired()
      .matches(/^\+\d[\d ]{9,19}$/, {
        message: t("Process.Applicants.Validation.PhoneNumberFormat"),
        excludeEmptyString: true,
      }),
    firstName: Yup.string().required(
      t("Process.Applicants.Validation.FirstNameRequired")
    ),
    lastName: Yup.string().required(
      t("Process.Applicants.Validation.LastNameRequired")
    ),
    languageCode: Yup.string().when("$currentState", (currentState, schema) => {
      if (currentState.includes(publishedState)) {
        return schema.required(
          t("Process.Applicants.Validation.LanguageCodeRequired")
        );
      }
      return schema.optional();
    }),
    officeId: Yup.string().when("$currentState", (currentState, schema) => {
      if (currentState.includes(publishedState)) {
        return schema.required(
          t("Process.Applicants.Validation.OfficeRequired")
        );
      }
      return schema.optional();
    }),
    individualInterviewDate: DateSchema.clone().optional(),
    individualInterviewTime: TimeSchema.clone().optional(),
    individualInterviewBooking: Yup.object().optional(),
    individualSimulationDate: DateSchema.clone().optional(),
    individualSimulationTime: TimeSchema.clone().optional(),
    groupSimulationDate: DateSchema.clone().optional(),
    groupSimulationTime: TimeSchema.clone().optional(),
    notes: Yup.string()
      .optional()
      .max(1000, t("Process.Validation.MaxLength", { maxChars: "1000" })),
    invitationSent: Yup.string().optional().nullable(),
    invitationEnabled: Yup.boolean().optional(),
    smsInvitation: Yup.object().optional()
  });

  const IndividualInterviewsSchema = Yup.object().shape({
    ordinal: Yup.number().optional(),
    interviewType: Yup.string().when(
      "$currentState",
      (currentState, schema) => {
        if (currentState.includes(publishedState)) {
          return schema
            .required()
            .oneOf(
              validInterviewTypes,
              t("Process.Interviews.Validation.IndividualInterviewTypeRequired")
            );
        }
        return schema.optional();
      }
    ),
    duration: Yup.number().optional().min(30).max(120).nullable(),
    timeframeStart: DateSchema.clone().optional(),
    timeframeEnd: DateSchema.clone().when(
      ["$currentState", "interviewType"],
      ([currentState, interviewType], schema) => {
        if (currentState.includes(publishedState) && (interviewType === "Teams" || interviewType === "Office")) {
          return schema.required(
            t("Process.Interviews.Validation.TimeframeEndRequired")
          );
        }
        return schema.optional();
      }
    ),
  });

  const IndividualSimulationsSchema = Yup.object().shape({
    ordinal: Yup.number().optional(),
    showInPortal: Yup.boolean().optional(),
    interviewType: Yup.string().optional(),
    notes: Yup.string()
      .optional()
      .max(1000, t("Process.Validation.MaxLength", { maxChars: "1000" })),
  });

  const GroupSimulationsSchema = Yup.object().shape({
    ordinal: Yup.number().optional(),
    interviewType: Yup.string().optional(),
    duration: Yup.number().optional().nullable(),
    notes: Yup.string()
      .optional()
      .max(1000, t("Process.Validation.MaxLength", { maxChars: "1000" })),
    language: Yup.string().optional(),
    timeframeStart: DateSchema.clone().optional(),
    timeframeEnd: DateSchema.clone().optional(),
  });

  const AssessmentSchema = Yup.object().shape({
    ordinal: Yup.number().optional(),
    providerId: Yup.number().optional(),
    assessmentId: Yup.number().optional(),
    name: Yup.string().optional(),
    value: Yup.boolean().optional(),
    options: Yup.array().of(Yup.string()).optional(),
    reports: Yup.array().of(Yup.string()).optional(),
    assessmentData: Yup.array().of(
      Yup.object().shape({
        candidateId: Yup.string(),
        url: Yup.string().optional(),
        userName: Yup.string().optional(),
        password: Yup.string().optional(),
        language: Yup.string().optional()
      })
    ),
  });

  const processSchema = Yup.object().shape({
    processState: Yup.string().optional(),
    jobTitle: Yup.string().required(
      t("Process.BasicDetails.Section1.Validation.JobTitle")
    ),
    customer: Yup.object().shape({
      name: Yup.string().required(
        t("Process.BasicDetails.Section1.Validation.CustomerName")
      ),
    }),
    assessmentLevel: Yup.string().when(
      "$currentState",
      (currentState, schema) => {
        if (currentState.includes(publishedState)) {
          return schema
            .required()
            .oneOf(
              validAssessmentLevels,
              t("Process.BasicDetails.Section1.Validation.AssessmentLevel")
            );
        } else {
          return schema.optional();
        }
      }
    ),
    serviceType: Yup.string().optional(),
    schedule: Yup.string()
      .optional()
      .max(1000, t("Process.Validation.MaxLength", { maxChars: "1000" })),
    consultants: Yup.array()
      .of(RequiredEmployeeSchema.clone())
      .when("$currentState", (currentState, schema) => {
        if (currentState.includes(publishedState)) {
          return schema.min(
            1,
            t(
              "Process.BasicDetails.Section3.Validation.AtLeastOneConsultantRequired"
            )
          );
        }
        return schema.optional();
      }),
    forepersons: Yup.array().of(EmployeeSchema.clone()),
    reportRecipients: Yup.array().of(EmployeeSchema.clone()),
    coordinators: Yup.array().of(EmployeeSchema.clone()),
    criteria: Yup.array().of(CriteriaSchema.clone()),
    customCriteria: Yup.array().of(CustomCriteriaSchema.clone()),
    applicants: Yup.array()
      .of(ApplicantSchema.clone())
      .when("$currentState", (currentState, schema) => {
        if (currentState.includes(publishedState)) {
          return schema.min(
            1,
            t("Process.Applicants.Validation.AtLeastOneApplicantRequired")
          );
        }
        return schema.optional();
      }),
    individualInterviews: Yup.array().of(IndividualInterviewsSchema.clone()),
    groupSimulations: Yup.array().of(GroupSimulationsSchema.clone()),
    individualSimulations: Yup.array().of(IndividualSimulationsSchema.clone()),
    processAssessments: Yup.array().of(AssessmentSchema.clone()),
    assessmentInstructions: Yup.string()
      .optional()
      .max(1000, t("Process.Validation.MaxLength", { maxChars: "1000" })),
    disabledApplicantAssessments: Yup.array().of(
      Yup.object().shape({
        candidateId: Yup.string().required(),
        assessmentId: Yup.number().required(),
      })
    ),
  });

  const disablePublishAction = () => {
    if (isPublished() || isNewProcess) {
      return true;
    }
    return !containsMinimumData();
  };

  const isPublished = () => {
    return formData.processState === publishedState;
  };

  const containsMinimumData = () => {
    return isNotBlank(formData.jobTitle) && isNotBlank(formData.customer.name);
  };

  const revalidate = (name, value) => {
    Yup.reach(processSchema, name)
      .validate(value, { context: { currentState: formData.processState } })
      .then(() => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: undefined,
        }));
      })
      .catch((error) => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: error.message,
        }));
      });
    closeNotification();
    setDataChanged(true);
  };

  const applyChange = (name, value) => {
    if (name.includes(".")) {
      const fieldPrefix = name.split(".")[0];
      const fieldSuffix = name.split(".")[1];
      updateFormData({
        ...formData,
        [fieldPrefix]: {
          ...formData[fieldPrefix],
          [fieldSuffix]: value,
        },
      });
    } else {
      updateFormData({ ...formData, [name]: value });
    }
    revalidate(name, value);
  };

  const addNewItemToFormData = (key, newItem) => {
    updateFormData({ ...formData, [key]: [...formData[key], newItem] });
    setDataChanged(true);
  };

  const updateItemsToFormData = (key, item) => {
    updateFormData({ ...formData, [key]: item });
    setDataChanged(true);
  };

  const clearErrors = (sectionKey, position) => {
    let keysToRemove = getMatchingKeys(sectionKey, position);
    keysToRemove.forEach((key) => {
      errors[key] = "";
    });
  };

  const getMatchingKeys = (sectionKey, position) => {
    if (isPositiveOrZero(position)) {
      return Object.keys(errors).filter((e) =>
        e.startsWith(`${sectionKey}[${position}]`)
      );
    }
    return Object.keys(errors).filter((e) => e === sectionKey);
  };

  const publishProcess = async (e) => {
    formData.processState = publishedState;
    setReadyToPublish(true);
    await submitForm(e);
  };

  const closeNotification = () => {
    setShowNotification(false);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    closeNotification();
    setSilentSaveMode(false);
    setTimeout(function () {
      setReadyToSubmit(true);
    }, 100);
  };

  const handleTabClick = (tabIndex) => {
    if (ongoingSave()) {
      return;
    }
    closeNotification();
    processSchema
      .validate(formData, {
        abortEarly: false,
        context: { currentState: formData.processState },
      })
      .then(() => {
        setTimeout(function () {
          if (dataChanged && !ongoingSave()) {
            setSilentSaveMode(true);
            setReadyToSubmit(true);
          }
          window.scrollTo(0, 0);
        }, 100);
      })
      .catch((validationErrors) => {
        const newErrors = {};
        validationErrors.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        setErrors(newErrors);
      })
      .finally(() => {
        setActiveTab(tabIndex);
      });
  };

  const ongoingSave = () => {
    return readyToSubmit || readyToPublish;
  };

  const isArchived = () => {
    return formData.processState === "Archived";
  };

  const showPublishedPill = () => {
    return !readyToPublish && isPublished() && !isArchived();
  };

  const getNotificationTitle = () => {
    if (!showNotification) {
      return "";
    } else if (savedSuccessfully) {
      return processPublished
        ? t("Process.Notifications.Success.TitlePublished")
        : t("Process.Notifications.Success.Title");
    } else if (!isEmptyJson(errors)) {
      return readyToPublish
        ? t("Process.Notifications.Invalid.PublishTitle")
        : t("Process.Notifications.Invalid.SaveTitle");
    }
    return t("Process.Notifications.Failure.Title");
  };

  const getNotificationContent = () => {
    if (!showNotification) {
      return "";
    } else if (savedSuccessfully) {
      return isPublished()
        ? t("Process.Notifications.Success.Saved")
        : t("Process.Notifications.Success.DraftSaved");
    } else if (!isEmptyJson(errors)) {
      return t("Process.Notifications.Invalid.Content");
    }
    return t("Process.Notifications.Failure.ErrorOccured");
  };

  const getSaveButtonLabel = () => {
    if (isPublished()) {
      return t("Process.Actions.Save");
    }
    return t("Process.Actions.SaveDraft");
  };

  useEffect(() => {
    // Reset form when switching from edit mode to new
    if (isNewProcess && processLoading) {
      setProcessLoading(false);
      updateFormData(JSON.parse(JSON.stringify(initialForm)));
      setActiveTab(1);
    }
  }, [isNewProcess, processLoading, formData, initialForm, activeTab]);

  useEffect(() => {
    const loadingErrorParams = [["error", "ProcessNotFound"]];
    const fetchData = async () => {
      setProcessLoading(true);
      setShowOverlay(true);
      await getProcessById(id)
        .then((response) => {
          updateFormData(addMissingProperties(response, employeeTypes));
          window.scrollTo(0, 0);
        })
        .catch((e) => {
          console.log("Could not load process data " + e);
          navigate({
            pathname: "/notFound",
            search: `?${createSearchParams(loadingErrorParams)}`,
          });
        })
        .finally(() => {
          setShowOverlay(false);
        });
    };
    if (!isNewProcess && !processLoading) {
      fetchData().catch(console.error);
    }
  }, [id, processLoading, isNewProcess, showOverlay, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      setChangeHistoryLoading(true);
      await getProcessChangeHistoryById(id)
        .then((response) => {
          setChangeHistory(response);
        })
        .catch((e) => {
          console.log("Could not load process audit log data " + e);
        });
    };
    if (!isNewProcess && !changeHistoryLoading) {
      fetchData().catch(console.error);
    }
  }, [id, changeHistoryLoading, changeHistory, isNewProcess]);

  const handleArchiveProcess = () => {
    updateFormData({ ...formData, "processState": "Archived" });
  }

  useEffect(() => {
    const saveProcessData = async () => {
      processSchema
        .validate(formData, {
          abortEarly: false,
          context: { currentState: formData.processState },
        })
        .then(async (formData) => {
          await saveProcess(formData, readyToPublish, silentSaveMode)
            .then((response) => {
              if (response?.id) {
                setSavedSuccessfully(true);
                setErrors({});
                if (readyToPublish) {
                  setProcessPublished(true);
                }
                setChangeHistoryLoading(false);
                setShowNotification(!silentSaveMode);
                updateFormData(addMissingProperties(response, employeeTypes));
                setDataChanged(false);
                navigate(`/process/${response.id}/edit`, { replace: false });
              } else if (response?.errors) {
                setSavedSuccessfully(false);
                if (readyToPublish) {
                  formData.processState = draftState;
                }
                const newErrors = {};
                Object.keys(response.errors).forEach(function (key) {
                  newErrors[key] = response.errors[key][0];
                });
                setErrors(newErrors);
                setShowNotification(true);
              } else {
                throw new Error("An error occurred");
              }
            })
            .catch(() => {
              setSavedSuccessfully(false);
              setProcessPublished(false);
              setShowNotification(true);
            });
        })
        .catch((validationErrors) => {
          const newErrors = {};
          validationErrors.inner.forEach((error) => {
            newErrors[error.path] = error.message;
          });
          setErrors(newErrors);
          setSavedSuccessfully(false);
          if (readyToPublish) {
            formData.processState = draftState;
          }
          setProcessPublished(false);
          setShowNotification(true);
        })
        .finally(() => {
          setReadyToSubmit(false);
          setReadyToPublish(false);
        });
    };
    if (readyToSubmit) {
      saveProcessData().catch(console.error);
    }
  }, [
    processSchema,
    formData,
    readyToSubmit,
    savedSuccessfully,
    navigate,
    changeHistoryLoading,
    readyToPublish,
    processPublished,
    errors,
    silentSaveMode,
    dataChanged
  ]);


  return (
    <AuthenticatedTemplate>
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <BreadCrumbs breadcrumbs={breadcrumbs} />
          <form>
            <div className="space-y-2 mt-6">
              <div className="flex items-center justify-between">
                <div className="flex content-start">
                  <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                    {t(titleTranslationKey)}
                  </h1>
                  {showPublishedPill() && (
                    <>
                      <Link
                        to={`/process/${id}/progress`}
                        className="font-medium text-mps-ultramarine dark:text-mps-ultramarine hover:underline"
                      >
                        <PresentationChartBarIcon
                          className="ml-4 mt-3 h-5 w-5 flex-shrink-0 text-mps-ultramarine"
                          aria-hidden="true"
                          title={t("Processes.List.ProgressAndReports")}
                        />
                      </Link>
                      <div className="pt-2 pl-2">
                        <GreenPill
                          text={t("Process.State.Published")}
                          includeDot={true}
                        />
                      </div>
                    </>
                  )}
                  {isArchived() && (
                    <div className="pt-2 pl-2">
                      <GrayPill
                        text={t("Process.State.Archived")}
                        includeDot={true}
                      />
                    </div>
                  )}
                </div>

                <div className="flex content-end">
                  <div className="col-span-full space-x-4">
                    {readyToSubmit && <LoadingIndicator type="small" />}
                    {!isArchived() && (
                      <ProcessActions
                        saveLabel={getSaveButtonLabel()}
                        saveAction={submitForm}
                        saveDisabled={!containsMinimumData() || ongoingSave()}
                        publishVisible={!showPublishedPill()}
                        publishDisabled={
                          disablePublishAction() || ongoingSave()
                        }
                        publishAction={publishProcess}
                      />
                    )}
                  </div>
                </div>
              </div>
              {!isNewProcess && !isEmptyJson(changeHistory) && (
                <div className="space-y-10">
                  <ChangeHistory changeHistory={changeHistory} />
                </div>
              )}
              {isArchived() && (
                <WarnBanner text={t("Process.Archived")} />
              )}
              <div className="space-y-10">
                <Tabs tabs={tabs} action={handleTabClick} errors={errors} />
                {activeTab === 1 && (
                  <BasicDetailsForm
                    formData={formData}
                    errors={errors}
                    validateAction={revalidate}
                    fieldUpdateAction={applyChange}
                    addToEmailGroupAction={addNewItemToFormData}
                    updateEmailGroupAction={updateItemsToFormData}
                    clearErrorsAction={clearErrors}
                    archiveAction={handleArchiveProcess}
                    archived={isArchived()}
                  />
                )}
                {activeTab === 2 && (
                  <InterviewsForm
                    formData={formData}
                    errors={errors}
                    updateAction={updateItemsToFormData}
                    validateAction={revalidate}
                    archived={isArchived()}
                  />
                )}
                {activeTab === 3 && (
                  <TestsAndMethodsForm
                    formData={formData}
                    errors={errors}
                    fieldUpdateAction={applyChange}
                    updateAction={updateItemsToFormData}
                    addNewItemAction={addNewItemToFormData}
                    removeItemAction={updateItemsToFormData}
                    validateAction={revalidate}
                    archived={isArchived()}
                  />
                )}
                {activeTab === 4 && (
                  <CriteriaForm
                    formData={formData}
                    errors={errors}
                    validateAction={revalidate}
                    addCriteriaAction={addNewItemToFormData}
                    updateCriteriaAction={updateItemsToFormData}
                    clearErrorsAction={clearErrors}
                    archived={isArchived()}
                  />
                )}
                {activeTab === 5 && (
                  <ApplicantsForm
                    formData={formData}
                    errors={errors}
                    updateAction={updateItemsToFormData}
                    validateAction={revalidate}
                    addNewApplicantAction={addNewItemToFormData}
                    removeApplicantAction={updateItemsToFormData}
                    clearErrorsAction={clearErrors}
                    archived={isArchived()}
                  />
                )}
                {activeTab === 6 && (
                  <InvitationMailForm serviceType={formData.serviceType} />
                )}
                <TabsFooter
                  tabs={tabs}
                  activeTab={activeTab}
                  action={handleTabClick}
                />
              </div>
            </div>
          </form>
        </div>
        {/* Global notification live region */}
        <div
          aria-live="assertive"
          className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
        >
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            <Notification
              title={getNotificationTitle()}
              content={getNotificationContent()}
              show={showNotification}
              action={closeNotification}
              isSuccess={savedSuccessfully}
              errors={errors}
            />
          </div>
        </div>
        <Overlay isActive={showOverlay} />
      </div>
    </AuthenticatedTemplate>
  );
}
