import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatAsLocalDateTime, isNotBlank } from "../../utils/uiUtils";
import { resendApplicantInvitationMail } from "../../api";
import { CheckIcon } from "@heroicons/react/24/solid";
import { LoadingIndicator } from "../../components/LoadingIndicator";

export const InvitationMessageEmail = ({ applicant, processId, disabled, handleInvitationResend }) => {
  const { t } = useTranslation();
  const [isResending, setIsResending] = useState(false);

  const resendInviteButtonClassName =
    !disabled && !isResending
      ? "text-blue-500 hover:text-blue-800"
      : "text-gray-500";

  const handleResendInvitationMail = (event) => {
    event.preventDefault();
    setIsResending(true);

    resendApplicantInvitationMail(processId, applicant.candidateId)
      .then((response) => {
        handleInvitationResend("invitationSent", response.invitationSent);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsResending(false);
      });
  };

  return (
    <>
      {isNotBlank(applicant.invitationSent) && (
        <div className="flex gap-x-1.5 text-sm w-full whitespace-nowrap mb-2">
          <div className="flex gap-x-1">
            <CheckIcon className="h-4 w-4 text-green-500" aria-hidden="true"/>
            <span>{t("Process.Applicants.InvitationMail.InvitationSent")}</span>
            <span>{formatAsLocalDateTime(applicant.invitationSent)}</span>
          </div>
          <button
            className={"text-sm " + resendInviteButtonClassName}
            onClick={handleResendInvitationMail}
            disabled={disabled || isResending}
          >
            <div>
              {t("Process.Applicants.InvitationMail.ResendMessage")}
              {isResending && (
                <span className="ml-1">
                  <LoadingIndicator type="small"/>
                </span>
              )}
            </div>
          </button>
        </div>
      )}
    </>
  );
};
