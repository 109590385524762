import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { isEmptyArray, isNotBlank } from "../../utils/uiUtils";
import { GrayPill, GreenPill, WarnPill } from "../../components/Pills";
import { PresentationChartBarIcon } from "@heroicons/react/20/solid";
import { PencilIcon } from "@heroicons/react/24/solid";

export function ProcessesListView({ processes }) {
  const { t } = useTranslation();

  const employeeName = (employees) => {
    if (isNotBlank(employees[0]?.lastName)) {
      return `${employees[0]?.lastName}, ${employees[0]?.firstName}`;
    }
    return employees[0]?.email;
  }

  return (
    <>
      {!isEmptyArray(processes) && (
        <table className="min-w-full">
          <thead>
          <tr>
            <th scope="col" className="py-3.5 pl-5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-5 w-1/12">
              {t("Processes.List.State")}
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-0 w-4/12">
              {t("Processes.List.Description")}
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-0 w-3/12">
              {t("Processes.List.Consultant")}
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-0 w-3/12">
              {t("Processes.List.Coordinator")}
            </th>
            <th scope="col" className="w-12"></th>
            <th scope="col" className="w-12"></th>
            <th scope="col" className="w-12"></th>
          </tr>
          </thead>
          <tbody className="divide-y divide-gray-300 bg-white">
            {processes.map((item) => (
              <tr key={item.id}>
                <td className="whitespace-nowrap text-sm w-32 px-2 py-3 ml-6 text-left">
                  {item.processState === "Published" ? (
                    <GreenPill text={t("Processes.List.Published")}/>
                  ) : item.processState === "Draft" ? (
                    <WarnPill text={t("Processes.List.Draft")}/>
                  ) : item.processState === "Archived" ? (
                    <GrayPill text={t("Processes.List.Archived")}/>
                  ) : null}
                </td>
                <td
                  className="whitespace-nowrap text-sm text-gray-900 sm:truncate truncate overflow-hidden overflow-ellipsis max-w-0">
                  {item.jobTitle} | {item.customer.name}
                </td>
                <td className="whitespace-nowrap text-sm text-gray-900 sm:truncate">
                  {employeeName(item.consultants)}
                </td>
                <td className="whitespace-nowrap text-sm text-gray-900 sm:truncate">
                  {employeeName(item.coordinators)}
                </td>
                <td className="whitespace-nowrap items-center text-sm text-gray-900 sm:pr-0 w-12 min-w-12">
                  {item.bookedApplicantsStatus && (
                    <span>{item.bookedApplicantsStatus}</span>
                  )}
                </td>
                <td className="relative whitespace-nowrapitems-centertext-sm font-medium sm:pr-0 w-12 min-w-12">
                  {item.processState === "Published" ||
                  item.processState === "Archived" ? (
                    <Link
                      to={`/process/${item.id}/progress`}
                      className="font-medium text-mps-ultramarine dark:text-mps-ultramarine hover:underline"
                    >
                      <PresentationChartBarIcon
                        className="h-5 w-5 flex-shrink-0 text-mps-ultramarine"
                        aria-hidden="true"
                        title={t("Processes.List.ProgressAndReports")}
                      />
                    </Link>
                  ) : (
                    <p className="text-gray-500">
                      <PresentationChartBarIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-700e"
                        aria-hidden="true"
                      />
                    </p>
                  )}
                </td>
                <td className="relative whitespace-nowrap items-center text-sm font-medium sm:pr-0 w-12 min-w-12">
                  <Link
                    to={`/process/${item.id}/edit`}
                    className="font-medium text-mps-ultramarine dark:text-mps-ultramarine hover:underline pr-4"
                  >
                    <PencilIcon
                      className="mt-4 h-5 w-5 flex-shrink-0 text-mps-ultramarine"
                      aria-hidden="true"
                      title={t("Processes.Actions.Edit")}
                    />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
}
