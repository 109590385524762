import { useTranslation } from "react-i18next";
import { formatAsLocalDate, formatTime } from "../../utils/uiUtils";
import React from "react";

export const InterviewSummary = ({ interviewType, interviewTypeValue, interviewDate, interviewTime }) => {
  const { t } = useTranslation();

  const label = `ProcessProgress.${interviewType}.Name`;
  const notScheduled = `ProcessProgress.${interviewType}.NotScheduled`;
  const noInterview = `ProcessProgress.${interviewType}.NoInterview`;

  return (
    <>
      {interviewTypeValue === "NoInterview" ? (
        <span className="text-gray-900">
          {t(noInterview)}
        </span>
      ) : (
        <>
          {t(label)}:{" "}
          {interviewDate ? (
            <span className="text-gray-900">
              {formatAsLocalDate(interviewDate)} {t("Generic.Time")} {formatTime(interviewTime)}
            </span>
          ) : (
            <span className="text-red-500">
              {t(notScheduled)}
            </span>
          )}
        </>
      )}
    </>
  );
};
